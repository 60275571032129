.cssload_container {
  position: relative;
}

.cssload_whirlpool,
.cssload_whirlpool::before,
.cssload_whirlpool::after {
  position: absolute;
  top: 50%;
  left: 50%;
  border: 1px solid rgb(204, 204, 204);
  border-left-color: rgb(214, 51, 214);
  border-radius: 624px;
  -o-border-radius: 624px;
  -ms-border-radius: 624px;
  -webkit-border-radius: 624px;
  -moz-border-radius: 624px;
}

.cssload_whirlpool {
  margin: -16px 0 0 -16px;
  height: 31px;
  width: 31px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload_whirlpool::before {
  content: '';
  margin: -14px 0 0 -14px;
  height: 27px;
  width: 27px;
  animation: cssload-rotate 1150ms linear infinite;
  -o-animation: cssload-rotate 1150ms linear infinite;
  -ms-animation: cssload-rotate 1150ms linear infinite;
  -webkit-animation: cssload-rotate 1150ms linear infinite;
  -moz-animation: cssload-rotate 1150ms linear infinite;
}

.cssload_whirlpool::after {
  content: '';
  margin: -18px 0 0 -18px;
  height: 35px;
  width: 35px;
  animation: cssload-rotate 2300ms linear infinite;
  -o-animation: cssload-rotate 2300ms linear infinite;
  -ms-animation: cssload-rotate 2300ms linear infinite;
  -webkit-animation: cssload-rotate 2300ms linear infinite;
  -moz-animation: cssload-rotate 2300ms linear infinite;
}

@keyframes cssload-rotate {
  100% {
    transform: rotate(360deg);
  }
}

@-o-keyframes cssload-rotate {
  100% {
    -o-transform: rotate(360deg);
  }
}

@-ms-keyframes cssload-rotate {
  100% {
    -ms-transform: rotate(360deg);
  }
}

@-webkit-keyframes cssload-rotate {
  100% {
    -webkit-transform: rotate(360deg);
  }
}

@-moz-keyframes cssload-rotate {
  100% {
    -moz-transform: rotate(360deg);
  }
}
