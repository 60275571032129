@tailwind base;
@tailwind components;

/* Fonts need to be defined before utilities */
@layer base {
  @font-face {
    font-family: 'Avenir Next';
    /* src: local('Avenir Next'), url('./assets/fonts/AvenirLTProMedium.otf') format('otf'); */
    src: local('Avenir Next'), url('./assets/fonts/AvenirNextLTPro-Regular.otf') format('otf');
  }
}

@tailwind utilities;

@layer utilities {
  /* Chrome, Safari and Opera */
  .scrollbar::-webkit-scrollbar {
    width: 8px;
  }

  .scrollbar::-webkit-scrollbar-track {
    background-color: 'transparent';
  }

  .scrollbar::-webkit-scrollbar-thumb {
    border-radius: 10px;
  }

  .scrollbar:hover::-webkit-scrollbar-thumb {
    background-color: rgb(151, 151, 151);
  }

  .scrollbar::-webkit-scrollbar-thumb:hover {
    background-color: rgb(131, 131, 131);
  }
  /* removes box around react-select inputs when focused */
  input[type='text']:focus { box-shadow: none; }
}
/* @layer base {
  html {
    @apply h-full;
  }
  body {
    @apply h-full;
  }
} */
